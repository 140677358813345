import React from "react";
import UrBox from "../../assets/fontawesome/image/mvcxurbox.png";
export default function FooterLogo() {
  return (
    <div className="fixed z-50 bottom-0 h-5 w-full flex justify-center items-center bg-[#ECF3FF]">
      <div className="flex w-[74px]">
        <img src={UrBox} className="w-[74px] " />
      </div>
      {/* <div className="ml-4 text-[9px] font-regular">|</div> */}
      <div className="ml-1 text-[11px] font-regular">
        © 2023 Powered by <span className="font-bold"> UrBox.</span>
      </div>
    </div>
  );
}
