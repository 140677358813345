import React, { useEffect, useState } from "react";
import logo_png from "../../assets/fontawesome/image/logo_png.png";
import gold_prize from "../../assets/fontawesome/image/gold-prize.png";
import "./AnnouncePrize.css";
import IconPhoneAndZalo from "../../component/IconPhoneAndZalo/IconPhoneAndZalo";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import FooterLogo from "../../component/Footer/FooterHasNavbar";
import { homeServices } from "../../services/apiService/homeServices";
import { setAuthorizationQR } from "../../services/apiService/configQrURL";
import { qrServices } from "../../services/apiService/qrservice";
const style = {
  // backgroundColor: '#47CCFF',
  color: "#130682",
  fontSize: "16px",
  fontHeight: "20px",
  fontWeight: "600",
};

export default function AnnouncePrize({
  handleChangeAnnounceStatus,
  handleEnableBtn,
}) {
  const { id } = useParams();
  console.log(id);
  const prize = JSON.parse(localStorage.getItem("PRIZE"));
  let appCode = window.location.pathname.split("/")[1];
  console.log(prize);
  // const winningGift = JSON.parse(localStorage.getItem("WINNING_GIFT"));
  const [isShorten, setIsShorten] = useState(true);
  const navigate = useNavigate();
  const handleShortingText = () => {
    setIsShorten(!isShorten);
  };
  const handleNavigateSucess = () => {
    navigate(`/${appCode}/doiqua-inputcode`);
    localStorage.removeItem("PRIZE");
  };
  const [winningGift, setGift] = useState();
  const token = JSON.parse(localStorage.getItem("TOKEN_QR"));
  console.log(token);
  setAuthorizationQR(token);
  useEffect(() => {
    const data = {
      so_id: id,
    };
    qrServices
      .getSOInfor(data)
      .then((res) => {
        console.log(res);
        setGift(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="">
        <div className=" annouce-prize contain text-[#ffffff]">
          <div className="pt-[20px]">
            <div className="flex flex-col items-center justify-center">
              <div>
                <img className="" src={logo_png} alt="" />
              </div>
              <div className="text-center">
                <h1 className="my-[18px] text-[38px] uppercase text-[#ffffff]">
                  {winningGift?.gift_code !== "cbmmls" ? "xin chúc mừng" : ""}
                </h1>
                <p className="text-[20px] font-light text-[#ffffff]">
                  {winningGift?.gift_code !== "cbmmls"
                    ? `Bạn đã trúng phần quà là`
                    : ""}
                </p>

                <span className="text-[23px] text-[#ffffff]">
                  {prize[0]?.gift_name}
                </span>
                <p className="mt-2 text-[13px] font-light text-[#ffffff]">
                  {prize[0]?.lineid}
                </p>
              </div>
              <div className="mt-[31px]">
                <img className="px-[30px]" src={prize[0]?.image} alt="" />
              </div>
            </div>
          </div>
          <div className="mx-auto mt-[25px] px-[39px] text-center text-[17px] font-semibold text-black">
            {winningGift?.gift_code !== "cbmmls" ? (
              <p className="">{winningGift?.conditions_receiving_prize}</p>
            ) : (
              ""
            )}
          </div>
          <div className="box-border flex justify-center py-[50px] text-[#333]">
            <button
              onClick={() => {
                handleNavigateSucess();
              }}
              type="submit"
              className="bg-[#014FA2] rounded-xl px-[32px] py-[15px] font-bold-mon leading-5 text-[#ffffff]"
            >
              Trở về trang chủ
            </button>
          </div>
        </div>
        <IconPhoneAndZalo />
        <FooterLogo />
      </div>
    </>
  );
}
