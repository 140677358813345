import BG_TOPCOUNTDOWN from "../../assets/fontawesome/image/bg-countdowntop.png";
import BG_BOTTOMCOUNTDOWN from "../../assets/fontawesome/image/bg-countdownbottom.png";
import BG_1 from "../../assets/fontawesome/image/bg1.png";
import BG_2 from "../../assets/fontawesome/image/bg-2.png";
import { useEffect, useState } from "react";
import { receiptServices } from "../../services/apiService/receiptServices";
import ModalGift from "./ModalGift";
import ConfirmPopupBtnHorizontal from "../../component/ConfirmPopupBtnHorizontal/ConfirmPopupBtnHorizontal";

export default function CountDownOrder({ errMsg, handlePopupErrorOk= () => {}, }) {
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenErr, setModalIsOpenErr] = useState(false);
  const [listPrize, setListPrize] = useState([]);
  const [so_ids, setSo_ids] = useState([]);
  const [status_lucky, set_status_lucky] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
      if (
        seconds === 0 ||
        seconds === 10 ||
        seconds === 20 ||
        seconds === 30 ||
        seconds === 40 ||
        seconds === 50
      ) {
        receiptServices
          .submitReceiptTimeApi()
          .then((res) => {
            console.log(res);
            if (res.so_ids.length !== 0) {
              setListPrize(res.prize_list);
              setSo_ids(res.so_ids);
              setModalIsOpen(true);
              set_status_lucky(res?.lucky_draw);
            }
          })
          .catch((err) => {
            console.log("err ", err);
            let a = "Dữ liệu không đúng. Kiểm tra lại."
            if(a === errMsg){
              console.log('hello');
              setModalIsOpenErr(true)
            }
            
          })
          .finally(() => {
            localStorage.removeItem("GCS_RESULT");
          });
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {}, []);

  return (
    <div>
      <div>
        <div className="relative top-0">
          <img src={BG_TOPCOUNTDOWN} />
        </div>
        <div className="relative -top-[1px] z-0">
          <img src={BG_BOTTOMCOUNTDOWN} />
          <div className="absolute -top-[52%] left-1/2 z-10 w-8/12 translate-y-1/2 -translate-x-1/2">
            <img src={BG_1} className="animation-rotate-bg1 w-full" />
            <div className="absolute top-1/2 left-1/2 w-10/12 -translate-y-1/2 -translate-x-1/2 ">
              <img src={BG_2} className="animation-rotate-bg2 w-full" />
            </div>
            <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-3xl text-white ">
              {seconds > 0 || minutes > 0 ? (
                <>
                  <p>
                    <span> </span>
                    {minutes < 10 ? ` 0${minutes}` : minutes} :&nbsp;
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                </>
              ) : (
                <button
                  disabled={seconds > 0 || minutes > 0}
                  // onClick={resendOTP}
                >
                  Gửi lại
                </button>
              )}
            </div>
            <div className="absolute -bottom-16 w-full text-center text-white">
              <div>
                Hệ thống đang xử lí
                <br /> vui lòng đợi trong giây lát ...
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalGift
        modalIsOpen={modalIsOpen}
        soIds={so_ids}
        listPrize={listPrize}
        status_lucky={status_lucky}
      ></ModalGift>
      {seconds > 0 || minutes > 0 || modalIsOpen === true ? (
        <></>
      ) : (
        <ConfirmPopupBtnHorizontal
          isShow={true}
          labelOk="Đồng ý"
          message={errMsg}
          handleOk={handlePopupErrorOk}
        />
      )}
      {
        modalIsOpenErr ? 
        <ConfirmPopupBtnHorizontal
          isShow={true}
          labelOk="Đồng ý"
          message={errMsg}
          handleOk={handlePopupErrorOk}
        />:null
      }
    </div>
  );
}
