export default function NotFound404() {
  return (
    <div className="container bg_default text-[#fff] w-screen min-w-full h-full min-h-screen px-[25px] flex flex-col box-border">
      <div className="absolute w-full left-0">
        <div className="bg-logo ">
          {/* <img src={LOGO} className="w-40" /> */}
        </div>
        <div className="bg-logo-ig relative">
          {/* <img src={ADENIED} className="img-denied" /> */}
          <span className="relative z-0 locate_start">4</span>
          <span className="relative z-10 locate_between">0</span>
          <span className="relative z-20 locate_last">4</span>
        </div>
      </div>
      <div className="font-bold-mon absolute top-1/2 -translate-y-1/2 text-[18px] px-5 flex justify-center text-center">
        Quý khách vui lòng kiểm tra lại đường link truy cập và tải lại trang.
        Xin cảm ơn!
      </div>
    </div>
  );
}
