import { useState } from "react";

import "../../assets/css/font-text.css";
import BTN_TC from "../../assets/fontawesome/image/btn-tc.svg";
import MOBIPH from "../../assets/fontawesome/image/mobifone.png";

export default function GiftItem({ infor }) {
  console.log(infor);
  const [status, setStatus] = useState(false);
  const [tc, setTC] = useState();
  const handleToggle = () => {
    setStatus(!status);
  };

  const handleClickTC = () => {
    setTC();
  };

  return (
    <>
      <div className="flex relative h-[52px] pl-[2px] mb-3">
        {/* <div> */}
        <img
          className="max-w-[40px] max-h-[60px] pl-2 pb-2 mt-2"
          src={infor.image}
          alt="img"
        />
        {/* </div> */}
        <div>
          <div className="ml-2">
            <div className="text-cut-notify-title text-[#0A63C1] text-[12px]">
              {infor.gift_name}
            </div>
            <p className="text-cut-notify font-bold-mon text-[12px]">
              [Chương trình Giải Tồn]
            </p>
            <div className="text-cut-notify-title text-[#363534] text-[12px]">
              {/* {infor.title} */}
            </div>
          </div>
          {/* <div
            className="absolute bottom-0 font-bold right-0 btn-tc-detail"
            onClick={handleClickTC}
          >
            <a href={infor?.tc_url} target="_blank" className="flex">
              Xem Thể Lệ <img src={BTN_TC} className="ml-2 w-4" />
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
}
