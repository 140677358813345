import { useNavigate } from "react-router-dom";
import "../../assets/css/backgroundListNotify.css";
import "../../assets/css/backgroundPhone.css";
import "../../assets/css/font-text.css";
export default function Header({ TITLE, buttonBack, filter, clickFilter }) {
  const navigation = useNavigate();
  const handleBack = () => {
    const check = localStorage.getItem("checkNoti");
    if (check === "home") {
      navigation("/");
    } else {
      navigation(buttonBack);
    }
  };
  const handleFilter = () => {
    navigation(clickFilter);
  };
  return (
    <div className="contain">
      <div className="containerNotify w-full min-h-full h-[280px] bg-no-repeat absolute left-0 top-0">
        <div className="containerNotify__header h-[14%] flex items-center p-[0_20px_0_20px]">
          <div className="containerNotify__header-content flex text-center justify-center leading-5 text-white text-[16px] ">
            {TITLE}
          </div>
          {filter ? (
            <img
              src={filter}
              className="ml-auto w-9 h-9"
              onClick={handleFilter}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
