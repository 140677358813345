import { axiosInstance, campaignURL, config } from "./configURL";

const data = JSON.stringify({});
const headers = {
  accept: "application/json, text/plain, */*",
  "content-type": "application/json;charset=UTF-8",
};
export const userServices = {
  getMyPrizeList: (statusGifts) => {
    return axiosInstance.post(
      `/marico/list_win`,
      {
        params: {
          page: 1,
          limit: 0,
          exchange_status: statusGifts,
        },
      }
      // , {
      //   headers: {
      //     'Content-Type': 'application/json;charset=UTF-8',
      //   },
      // }
    );
  },

  getCampaignInfo: () => {
    return axiosInstance.get("/ocr/campaign_info", {
      params: {
        url: campaignURL,
      },
    });
  },
  postUserLogin: (data) => {
    return axiosInstance.post("/marico/login", { params: data });
  },
  postLogout: () => {
    return axiosInstance.post("/marico/logout", {});
  },
  postUserLoginNoRegister: (data) => {
    return axiosInstance.post("/marico/sign_in", { params: data });
  },

  postValidateOTP: (data) => {
    return axiosInstance.post("/marico/validate_otp", { params: data });
  },
  postResendOTP: () => {
    return axiosInstance.post("/mvc/resend_otp", {
      params: {
        url: campaignURL,
      },
    });
  },
  getUserInfo: (id) => {
    return axiosInstance.get(`/marico/customer_info?pnj_customer_id=${id}`);
  },

  postUserInfo: (userInfo) => {
    return axiosInstance.post("/marico/update_customer_info", {
      params: userInfo,
    });
  },
  postUpdateConsultant: (data) => {
    return axiosInstance.post("/marico/customer_confirm", { params: data });
  },
};
