import { axiosQrInstance } from "./configQrURL";
import { axiosInstance, campaignURL } from "./configURL";

export const homeServices = {
  getCampaignInfo: () => {
    return axiosInstance.get("/marico/campaign_info", {
      params: {
        url: campaignURL,
      },
    });
  },
  getSOInfor: (data) => {
    return axiosQrInstance.post("/marico/so_info", {
      params: data,
    });
  },
  postUpdateConsultant: (data) => {
    return axiosQrInstance.post("/marico/customer_confirm", { params: data });
  },
  getRunningCampaignAll: () => {
    return axiosInstance.post("/marico/get_all_campaign", {
      headers: "Content-Type: application/json",
    });
  },
  getCampaignClip: () => {
    return axiosInstance.post("/marico/get_homepage_clip", {
      headers: "Content-Type: application/json",
    });
  },
  getRunningCampaignTopAndDown: () => {
    return axiosInstance.post("/marico/get_homepage_banner", {
      headers: "Content-Type: application/json",
    });
  },
  getRunningCampaign: () => {
    return axiosInstance.post("/marico/get_running_campaign", {
      headers: "Content-Type: application/json",
    });
  },
  getHomepageBannerApi: () => {
    return axiosInstance.post(
      "/marico/get_homepage_banner",
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
};
