import React, { useEffect, useState } from "react";
import ModalGift from "../../pages/GuideTakeAPhoto/ModalGift";
import ConfirmPopupBtnHorizontal from "../../component/ConfirmPopupBtnHorizontal/ConfirmPopupBtnHorizontal";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import { format } from "date-fns";
import { receiptServices } from "../../services/apiService/receiptServices";
import ConfirmPopupLogin from "../ConfirmPopupLogin/ConfirmPopupLogin";
import { qrServices } from "../../services/apiService/qrservice";

export default function SubmitReceipt({
  typePhone,
  trigger,
  phoneCustomer,
  nameCustomer,
}) {
  let appCode = window.location.pathname.split("/")[1];
  console.log(appCode);
  let gcsResult = JSON.parse(localStorage.getItem("GCS_RESULT"));
  const phoneType = localStorage.getItem("PHONE_TYPE");
  const staffName = localStorage.getItem("STAFF_NAME");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [listPrize, setListPrize] = useState([]);
  const [so_ids, setSo_ids] = useState([]);
  const [status_lucky, setStatus_lucky] = useState(false);
  const [isShowModalErr, setIsShowModalErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const navigation = useNavigate();
  useEffect(() => {
    console.log(trigger);
    if (trigger) {
      submitReceipt(gcsResult);
    }
  }, [trigger]);

  const submitReceipt = (gcsResult) => {
    console.log(gcsResult);
    setIsUpload(true);
    let formData = new FormData();
    formData.append("gsutil_url", gcsResult.gsutil_url);
    formData.append("public_url", gcsResult.public_url);
    formData.append("ocr_result", gcsResult.data);
    formData.append("phone_type", typePhone);
    formData.append("username", staffName);
    formData.append("customer_name", nameCustomer);
    formData.append("phone", phoneCustomer);
    formData.append("endpoint_url", `https://marico.urbox.vn/${appCode}`);
    if (gcsResult?.campaign_id) {
      formData.append("campaign_id", gcsResult?.campaign_id);
    }
    formData.append(
      "request_id",
      uuid() + "-" + format(new Date(), "ddMMyyyyHHmmss")
    );
    formData.append(
      "receipt_datetime",
      format(new Date(), "yyyy-MM-dd HH:mm:ss")
    );
    receiptServices
      .submitReceiptApi(formData)
      .then((res) => {
        console.log(res);
        setStatus_lucky(res.lucky_draw);
        setListPrize(res.prize_list);
        setSo_ids(res.so_ids);
        localStorage.setItem("PRIZE", JSON.stringify(res.prize_list));
        const data = {
          so_id: res.so_ids[0],
        };
        console.log(data);
        qrServices
          .getSOInfor(data)
          .then((res) => {
            console.log(res);
            setGift(res);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            localStorage.removeItem("GCS_RESULT");
          });
        setModalIsOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setErrMsg(err);
        setIsShowModalErr(true);
      })
      .finally(() => {
        setIsUpload(false);
        localStorage.removeItem("GCS_RESULT");
      });
  };
  const handlePopupErrorOk = () => {
    console.log("popup press ok");
    navigation("/");
  };
  const [gift, setGift] = useState();
  useEffect(() => {
    console.log(so_ids);
  }, [so_ids]);

  return (
    <>
      {isShowModalErr ? (
        <ConfirmPopupLogin
          titlePopup={errMsg}
          btnAgree="Đồng ý"
          handleOk={() => {
            navigation(`/${appCode}/doiqua-inputcode`);
          }}
        />
      ) : null}
      {isUpload ? (
        <div className="full-height absolute z-[200] flex w-full flex-col items-center justify-center bg-[#014FA2] backdrop-blur-sm">
          <div className="">
            <button className="load h-[100px] w-[100px]"></button>
          </div>
          <div className="flex justify-center px-[30px] text-center ">
            <p className="rounded-xl bg-slate-100 p-[10px] font-light text-black">
              Hóa đơn của quý khách đang được hệ thống ghi nhận. Vui lòng không
              rời khỏi màn hình hoặc tắt trình duyệt
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      <ModalGift
        modalIsOpen={modalIsOpen}
        soIds={so_ids}
        listPrize={listPrize}
        status_lucky={status_lucky}
      ></ModalGift>
    </>
  );
}
