import "../../assets/css/backgroundNotifyGift.css";
import Image from "../../assets/fontawesome/image/success.png";
import HEADER from "../../assets/fontawesome/image/header-popup.png";
import "../../assets/css/font-text.css"
export default function ConfirmPopupLogin({ btnAgree, titlePopup, handleOk }) {
  const handleClickOk = () => {
    handleOk();
  };
  return (
    <div className="z-50">
      <div
        className="fixed z-50 overflow-y-auto top-0 w-full left-0 show"
        id="modal"
      >
        <div
          className="flex items-center justify-center min-height-100vh
         px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div className="fixed inset-0 transition-opacity px-5">
            <div className="absolute inset-0 bg-gray-900 opacity-70 z-10 px-5" />
            <div className="content-popup-login w-full relative z-50">
              <div className="relative">
                <img src={HEADER} className="w-full rounded-t-[35px]" />
                <div className="absolute top-10 bg-white w-full min-h-[300px] rounded-[32px]">
                  <div className="relative -top-6">
                    <img
                      src={Image}
                      className="absolute -top-1/2 left-1/2 -translate-x-1/2 w-16"
                    />
                  </div>
                  <div className="relative top-12 font-semibold-mon">
                    <div className="font-bold-mon text-[24px] text-[#014FA2]">
                      THÔNG BÁO
                    </div>
                    <div className="mt-5 px-5 font-regular-mon">{titlePopup}</div>

                    <div className="my-5 text-white flex justify-around px-10">
                      <button
                        className="bg-[#014FA2] w-8/12 py-4 rounded-2xl"
                        onClick={handleClickOk}
                      >
                        {btnAgree}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
