import { Carousel } from "react-responsive-carousel";
import ICON_DOTS from "../../assets/fontawesome/image/icon-dots.svg";
import ICON_DOTS_PRIMARY from "../../assets/fontawesome/image/icon-dots-primary.svg";
import BTN_NEXT_GUIDE from "../../assets/fontawesome/image/btn-cancel-guide.svg";
import B1_TAKEAPHOTO from "../../assets/fontawesome/image/m1.png";
import B2_TAKEAPHOTO from "../../assets/fontawesome/image/m2.png";
import B3_TAKEAPHOTO from "../../assets/fontawesome/image/m3.png";
import CarouselTakeAPhoto from "./CarouselTakeAPhoto";
import GiftIconImg from "../..//assets/fontawesome/image/gift.png";
import P from "../../assets/fontawesome/image/p.png";
import ICON_EXPLAIN from "../../assets/fontawesome/image/icon-explain.svg";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Compressor from "compressorjs";
import { format } from "date-fns";
import uuid from "react-uuid";
import { campaignServices } from "../../services/apiService/campaignServices";
import { homeServices } from "../../services/apiService/homeServices";
import { ocrServices } from "../../services/apiService/ocrServices";
import { receiptServices } from "../../services/apiService/receiptServices";
import "./GuideTakePhoto.css";
import { toast } from "react-toastify";
import { userDataLocal } from "../../services/localService/localService";
import ConfirmPopupLogin from "../../component/ConfirmPopupLogin/ConfirmPopupLogin";
import ModalGift from "./ModalGift";
import ConfirmPopupBtnHorizontal from "../../component/ConfirmPopupBtnHorizontal/ConfirmPopupBtnHorizontal";
import CountDownOrder from "./CountDownOrder";
import BACK from "../../assets/fontawesome/image/back.png";
import NavbarHome from "../../component/NavbarHome/NavbarHome";
import { setAuthorizationQR } from "../../services/apiService/configQrURL";

// import { requestCameraPermission } from "zmp-sdk/apis";

export default function GuideTakeAPhoto() {
  const [imageFile, setimageFile] = useState(undefined);
  const [isShowModalErr, setIsShowModalErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  let [ocrEndpoint, setOcrEndpoint] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [listPrize, setListPrize] = useState([]);
  const [so_ids, setSo_ids] = useState([]);
  const [isAskLogin, setIsAskLogin] = useState(false);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("TOKEN_QR"));
  console.log(token);
  setAuthorizationQR(token);
  let { campaignId } = useParams();
  console.log(campaignId);
  let refInputUpload = useRef(null);

  const onClickUpload = (event) => {
    // requestCameraPermission({
    //   success: ({ userAllow, message }) => {
    //     console.log("thanh cong: ", message);
    //     if (userAllow) {
    refInputUpload.current?.click();
    //     }
    //   },
    //   fail: (err) => {
    //     console.log("loi: ", message);
    //     console.log(err);
    //   },
    // });
  };

  const handlePopupErrorOk = () => {
    console.log("popup press ok");
    navigate("/");
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleChangeImage = (event) => {
    console.log(event);
    let fileUploaded = event.target.files[0];
    console.log(fileUploaded);
    const fileUploadedSize = fileUploaded.size / 1024 / 1024;
    if (fileUploadedSize > 20) {
      new Compressor(fileUploaded, {
        quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else if (fileUploadedSize > 10 && fileUploadedSize <= 20) {
      new Compressor(fileUploaded, {
        quality: 0.5, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else if (fileUploadedSize > 6 && fileUploadedSize <= 10) {
      new Compressor(fileUploaded, {
        quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else if (fileUploadedSize > 3 && fileUploadedSize <= 6) {
      new Compressor(fileUploaded, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (res) => {
          setimageFile(res);
        },
      });
    } else {
      setimageFile(fileUploaded);
    }
    console.log(fileUploaded);
  };
  const images = [
    {
      id: 1,
      url: B1_TAKEAPHOTO,
      title: `
      <ul id="a">
      <li>Chụp hóa đơn GỐC và chụp TOÀN BỘ hóa đơn
      </li>
      <li>Thấy rõ Tên siêu thị, số hóa đơn, sản phẩm, ngày mua.
      </li>
      </ul>`,
    },
    {
      id: 2,
      url: B2_TAKEAPHOTO,
      title: `
      <ul id="a">
      <li>Chụp hóa đơn RÕ RÀNG trong điều kiện đủ ánh sáng.
      </li>
      <li>Nền phía sau trơn - đơn giản
      </li>
      <li>Chú ý không dùng tay che hóa đơn</li>
      </ul>`,
    },
    {
      id: 3,
      url: B3_TAKEAPHOTO,
      title: `
      <ul id="a">
      <li>Gấp hóa đơn lại để hiển thị rõ phần tên sản phẩm và số lượng
      </li>
      <li>Không gấp hoặc che các thông tin ở phần đầu và cuối hóa đơn
      </li>
      </ul>`,
    },
  ];

  const [image, setImage] = useState(undefined);

  const getCampaignDetail = (campaignId) => {
    campaignServices
      .getCampaignDetailApi(campaignId)
      .then((res) => {
        console.log(res);
        setOcrEndpoint(res.ocr_endpoint);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getHomepageBanner = () => {
    homeServices
      .getHomepageBannerApi()
      .then((res) => {
        console.log(res);
        setOcrEndpoint(res.ocr_endpoint);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOcrEndPoint = (campaignId) => {
    if (campaignId) {
      getCampaignDetail(campaignId);
    } else {
      getHomepageBanner();
    }
  };

  useEffect(() => {
    getOcrEndPoint(campaignId);
  }, [campaignId]);
  let appCode = window.location.pathname.split("/")[1];
  console.log(appCode);
  const pushImageToGCS = () => {
    let formDataGCS = new FormData();
    formDataGCS.append("file", imageFile);
    const fileName =
      uuid() +
      "_" +
      format(new Date(), "dd-MM-yyyy-HH-mm-ss") +
      "_" +
      imageFile.name;
    formDataGCS.append("fileName", fileName);
    formDataGCS.append("ocrBase", ocrEndpoint);
    // if (!token) {
    //   setIsAskLogin(true);
    // } else {
    setIsUpload(true);
    // }
    ocrServices
      .uploadImageToOCR(formDataGCS)
      .then((res) => {
        navigate(`/${appCode}/doiqua-kh`);

        console.log(res);
        if (campaignId) {
          console.log("campaignId", campaignId);
          res.data.campaign_id = campaignId;
        }
        localStorage.setItem("GCS_RESULT", JSON.stringify(res.data));
      })
      .then((res) => {
        if (token) {
          console.log("campaignId", campaignId);

          let gcsResult = JSON.parse(localStorage.getItem("GCS_RESULT"));
          // submitReceipt(gcsResult);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsUpload(false);
      });
  };
  const onClickDeleteImg = (e) => {
    setimageFile(undefined);
    setImage(undefined);
  };
  const [status_lucky, set_status_lucky] = useState(false);
  const submitReceipt = (gcsResult) => {
    console.log(gcsResult);
    let formData = new FormData();
    formData.append("gsutil_url", gcsResult.gsutil_url);
    formData.append("public_url", gcsResult.public_url);
    formData.append("ocr_result", gcsResult.data);
    // formData.append(
    //   "gsutil_url",
    //   `gs://mvcpro_vn/2d3c7ea4-8f4b-a2d3-1cfa-cdc5c0803fab_14-05-2024-16-51-11_image.jpg`
    // );
    // formData.append(
    //   "public_url",
    //   `https://storage.googleapis.com/mvcpro_vn/2d3c7ea4-8f4b-a2d3-1cfa-cdc5c0803fab_14-05-2024-16-51-11_image.jpg`
    // );
    // formData.append(
    //   "ocr_result",
    //   `{
    //       "customer_name": "Coopmart Xa Lo Ha Noi",
    //       "counter": "30",
    //       "cashier": "16113022-Cat Tuyen",
    //       "date_time": "04/10/2023 08:51:11",
    //       "order_number": "03886",
    //       "barcode": "0016103023100403886",
    //       "tax_number": "0305767459",
    //       "runtime": "2.28",
    //       "product_list": [
    //           {
    //               "barcode": "8936065070042",
    //               "description": "ST Purite hoa hong 500ml",
    //               "quantity": 1,
    //               "unitPrice": "159900",
    //               "lineTotalNet": "159900"
    //           },
    //           {
    //               "barcode": "8936065072213",
    //               "description": "ST olive d.am mem min 250ml",
    //               "quantity": 1,
    //               "unitPrice": "75400",
    //               "lineTotalNet": "75400"
    //           }
    //       ],
    //       "promotion": true,
    //       "received_creceipt_datetime": "2024-05-14 16:51:12",
    //       "response_result_datetime": "2024-05-14 16:51:14",
    //       "chain": "coopmart"
    //   }`
    // );

    formData.append(
      "request_id",
      uuid() + "-" + format(new Date(), "ddMMyyyyHHmmss")
    );
    formData.append(
      "receipt_datetime",
      format(new Date(), "yyyy-MM-dd HH:mm:ss")
    );
    if (gcsResult.campaign_id) {
      formData.append("campaign_id", gcsResult.campaign_id);
    }
    receiptServices
      .submitReceiptApi(formData)
      .then((res) => {
        set_status_lucky(res?.lucky_draw);
        console.log("result ", res);
        console.log("soid ", res.so_ids);
        setListPrize(res.prize_list);
        setSo_ids(res.so_ids);
        toast.success(res);
        setIsUpload(false);
        setIsOpen(true);
      })
      .catch((err) => {
        console.log("err ", err);
        setErrMsg(err);
        setIsShowModalErr(true);
        setIsUpload(false);
      })
      .finally(() => {
        localStorage.removeItem("GCS_RESULT");
      });
  };

  function urltoFile(url, filename, mimeType) {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }
  useEffect(() => {
    console.log(image);
    if (image !== undefined) {
      urltoFile(image, uuid() + uuid() + ".jpg", "image/jpeg").then(
        function (file) {
          console.log(file);
          handleChangeImage(file);
        }
      );
    }
  }, [image]);

  return (
    <>
      {isUpload ? (
        <div className="full-height absolute z-[200] flex w-full flex-col items-center justify-center backdrop-blur-sm">
          <div className="">
            <button className="load h-[100px] w-[100px]"></button>
          </div>
          <div className="flex justify-center px-[30px] text-center ">
            <p className="rounded-xl bg-slate-100 p-[10px] font-light ">
              Hóa đơn của quý khách đang được hệ thống ghi nhận. Vui lòng không
              rời khỏi màn hình hoặc tắt trình duyệt
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      <>
        {isShowModalErr ? (
          <CountDownOrder
            errMsg={errMsg}
            handlePopupErrorOk={handlePopupErrorOk}
          />
        ) : (
          <Fragment>
            {image !== undefined ? (
              <div>
                <img
                  src={URL.createObjectURL(imageFile)}
                  className="rounded-3xl"
                  alt="upload view"
                />

                <div className="flex justify-center gap-[12px] p-4">
                  <button
                    onClick={(e) => onClickDeleteImg(e)}
                    className="inline-block cursor-pointer rounded-[13px] border-0 bg-[#47CCFF] px-[32px] py-[15px] text-center font-[Montserrat-Light] text-[16px] font-black not-italic leading-5 text-[#130682] no-underline"
                  >
                    Chụp lại
                  </button>

                  <button
                    onClick={() => {
                      pushImageToGCS();
                    }}
                    className="inline-block cursor-pointer rounded-[13px] border-0 bg-[#0367C1] px-[32px] py-[15px] text-center font-[Montserrat-Light] text-[16px] font-black not-italic leading-5 text-[#FFFFFF] no-underline"
                  >
                    Xác nhận
                  </button>
                </div>
                {isAskLogin ? (
                  <ConfirmPopupLogin
                    image={GiftIconImg}
                    oneButton="oneButton"
                    titlePopup="Đăng ký thông tin để nhận phần quà hấp dẫn từ chúng tôi"
                    handleCancel={() => {
                      navigate("/login-no-register");
                    }}
                    handleOk={() => {
                      navigate("/login-no-register");
                    }}
                  />
                ) : null}
                <ModalGift
                  modalIsOpen={modalIsOpen}
                  soIds={so_ids}
                  listPrize={listPrize}
                ></ModalGift>
                <ConfirmPopupBtnHorizontal
                  isShow={isShowModalErr}
                  labelOk="Đồng ý"
                  message={errMsg}
                  handleOk={handlePopupErrorOk}
                />
              </div>
            ) : (
              <div className={`relative ${isUpload ? "is-upload" : ""} `}>
                <div className="">
                  <div className=" flex flex-col items-center bg-white pt-3">
                    {imageFile !== undefined ? (
                      <div>
                        <div className="takepic-img-wrap">
                          <img
                            src={URL.createObjectURL(imageFile)}
                            className="mt-4 px-[20px]"
                            alt="upload view"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="z-50">
                          <div
                            className="fixed z-50 overflow-y-auto top-0 w-full left-0 show"
                            id="modal"
                          >
                            <div
                              className="flex items-center justify-center min-height-100vh
         px-4 pb-20 text-center sm:block sm:p-0"
                            >
                              <div className="fixed inset-0 transition-opacity">
                                <div className="absolute inset-0 bg-gray-900 opacity-70 z-10 px-5" />
                                <div className="content-popup-condition w-full relative top-5 z-20 px-5 reponsive-phonepoup h-[100%]">
                                  <div className="containerNotify__background-photo rounded-3xl h-[85%] reponsive-phone bg-[#014FA2] py-3">
                                    <ul className="containerNotify__background-list rounded-3xl h-[100%]">
                                      <div className="flex w-full flex-col bg-white items-center justify-center h-[100%] ">
                                        <div className="w-10/12 rounded-xl px-3 mb-5">
                                          <Carousel
                                            className="style-carousel"
                                            autoPlay
                                            centerMode={false}
                                            showArrows={true}
                                            infiniteLoop={true}
                                            showThumbs={false}
                                            showStatus={false}
                                            preventMovementUntilSwipeScrollTolerance={
                                              false
                                            }
                                            renderIndicator={(
                                              onClickHandler,
                                              isSelected,
                                              index,
                                              label
                                            ) => {
                                              const defStyle = (
                                                <img
                                                  src={ICON_DOTS}
                                                  className="h-3 w-3"
                                                />
                                              );
                                              const defStylePrimary = (
                                                <img
                                                  src={ICON_DOTS_PRIMARY}
                                                  className="h-3 w-3"
                                                />
                                              );
                                              const style = isSelected
                                                ? { ...defStylePrimary }
                                                : { ...defStyle };
                                              return (
                                                <span
                                                  style={{
                                                    display: "inline-block",
                                                    padding: "105px 4px",
                                                  }}
                                                  className="inline-block px-2"
                                                  onClick={onClickHandler}
                                                  onKeyDown={onClickHandler}
                                                  value={index}
                                                  key={index}
                                                  role="button"
                                                  tabIndex={0}
                                                  aria-label={`${label} ${
                                                    index + 1
                                                  }`}
                                                >
                                                  {style}
                                                </span>
                                              );
                                            }}
                                          >
                                            {images.map((item, index) => (
                                              <div key={index}>
                                                <CarouselTakeAPhoto
                                                  item={item}
                                                />
                                              </div>
                                            ))}
                                          </Carousel>
                                        </div>
                                        <div className="mx-6 mt-[55px] max-[390px]:mt-[40px] flex w-full rounded-lg bg-[#ffffff]">
                                          <div className="mt-3 flex w-16 items-start justify-center">
                                            <img src={ICON_EXPLAIN} />
                                          </div>
                                          <div className="mt-3 w-full">
                                            <div className="title-description-bill ">
                                              Hóa đơn quá dài{" "}
                                            </div>
                                            <div className="content-description-bill mt-1 font-regular-mon text-left">
                                              Có thể gấp hóa đơn lại để hiển thị
                                              rõ phần tên sản phẩm và số lượng.
                                              Không gấp hoặc che các thông tin ở
                                              phần đầu và cuối hóa đơn
                                            </div>

                                            <div className="-ml-[52px] flex">
                                              <div className="mt-3 flex w-16 items-start justify-center">
                                                <img src={P} />
                                              </div>
                                              <div className="content-description-bill ml-1 mt-1 font-regular-mon  mb-2 text-left">
                                                Liên hệ Hotline để được hướng
                                                dẫn chi tiết Số hotline{" "}
                                                <a
                                                  href="tel:02899995668"
                                                  className="font-semibold-mon"
                                                >
                                                  02899995668
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="mt-[15px] mb-[20px] flex justify-between">
                                          <div className="color-button-disable btn-takephoto mr-2 ">
                                            <NavLink
                                              to={`/${appCode}/doiqua-inputcode`}
                                              className="text-btn-cancel btn-text flex items-center justify-center"
                                            >
                                              <div className="w-2 mr-2">
                                                <img
                                                  src={BACK}
                                                  className="w-3"
                                                />
                                              </div>
                                              Trở lại
                                            </NavLink>
                                          </div>
                                          <div className="btn-takephoto ml-2 bg-[#014FA2] ">
                                            <div className="ml-3 flex flex-col justify-center">
                                              <img
                                                src={BTN_NEXT_GUIDE}
                                                className=" h-6 w-6"
                                              />
                                            </div>

                                            <button
                                              onClick={(e) => onClickUpload(e)}
                                              className="text-btn-cancel btn-text corlor-text-white -ml-3 flex items-center justify-center"
                                            >
                                              Bỏ qua hướng dẫn
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <NavbarHome /> */}
                      </>
                    )}
                    {imageFile ? (
                      <div className="flex gap-[12px] p-4">
                        <button
                          onClick={(e) => onClickDeleteImg(e)}
                          className="color-button-enable font-semibold-mon rounded-[13px] px-[32px] py-[15px] text-center text-[16px] text-[#ffffff]"
                        >
                          Chụp lại
                        </button>
                        <button
                          onClick={() => {
                            pushImageToGCS();
                          }}
                          className="rounded-[13px] border-0 bg-[#014FA2] px-[32px] py-[15px] text-[#FFFFFF] font-semibold-mon"
                        >
                          Xác nhận
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    <input
                      onChange={(e) => handleChangeImage(e)}
                      className="uploadFile"
                      style={{ display: "none" }}
                      id="upload"
                      type="file"
                      capture
                      accept="image/*"
                      ref={refInputUpload}
                    />
                  </div>
                  <ModalGift
                    modalIsOpen={modalIsOpen}
                    soIds={so_ids}
                    listPrize={listPrize}
                    status_lucky={status_lucky}
                  ></ModalGift>
                  {isAskLogin ? (
                    <ConfirmPopupLogin
                      oneButton="oneButton"
                      image={GiftIconImg}
                      titlePopup="Đăng ký thông tin để nhận phần quà hấp dẫn từ chúng tôi"
                      handleCancel={() => {
                        navigate("/login");
                      }}
                      handleOk={() => {
                        navigate("/login");
                      }}
                    />
                  ) : null}
                </div>
              </div>
            )}
          </Fragment>
        )}
      </>
    </>
  );
}
