import ConfirmPopupLogin from "../../component/ConfirmPopupLogin/ConfirmPopupLogin";
import ICON_HOME_COLOR from "../../assets/fontawesome/image/home_icon_color.png";
import ICON_PROFILE_COLOR from "../../assets/fontawesome/image/profile_icon-color.png";
import ICON_GIFT_COLOR from "../../assets/fontawesome/image/gift_icon-color.png";
import ICON_TAKEPHOTO_COLOR from "../../assets/fontawesome/image/takephoto_icon-color.svg";
import "../../assets/css/Home.css";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import FooterLogo from "../Footer/FooterHasNavbar";
import { USER_DATA_LOCAL } from "../../services/localService/localService";
let titlePopup = "Vui lòng đăng nhập để thấy được chức năng này";
export default function NavbarHome({}) {
  const location = useLocation();
  // let { token } = useSelector((state) => {
  //   return state.userReducer.userData;
  // });
  // console.log(token);
  const { token } = JSON.parse(localStorage.getItem(USER_DATA_LOCAL) || "{}");
  const [popup, setPopup] = useState(false);
  const navigation = useNavigate();
  const handleClickMenuHome = () => {
    navigation("/");
  };
  // console.log(token);
  const handleClickMenuTakePhoto = () => {
    navigation("/guide-takeaphoto");
  };
  const handleClickMenuProfile = () => {
    console.log(token);
    if (
      token === "" ||
      token === null ||
      token === undefined ||
      token === "undefined"
    ) {
      setPopup(true);
    } else {
      navigation("/infor-customer");
    }
  };
  const handleClickMenuGift = () => {
    navigation("/list-gift");
  };
  return (
    <div className="navbar__home w-[100%] h-[78px] rounded-t-2xl bg-[#fff] fixed bottom-0 left-0 z-20">
      <div className="flex justify-between">
        <ul className="px-[1.5625rem] mt-3 sm:mt-7 h-12 w-full flex justify-between">
          {location?.pathname === "/" ? (
            <li className="icon-nav-home max-[423px]:w-[10rem] w-36 animate-waving-hand ">
              <img src={ICON_HOME_COLOR} className="w-40 h-10" />
            </li>
          ) : (
            <li
              className="navbar__home-Iconhome icon-navbar "
              onClick={handleClickMenuHome}
            ></li>
          )}

          {location?.pathname === "/guide-takeaphoto" ? (
            <li className="icon-nav-home max-[423px]:w-[10rem] w-36 animate-waving-hand ">
              <img src={ICON_TAKEPHOTO_COLOR} className="w-40 h-12" />
            </li>
          ) : (
            <li
              className=" navbar__home-Icontake icon-navbar"
              onClick={handleClickMenuTakePhoto}
            ></li>
          )}

          {location?.pathname === "/list-gift" ? (
            <li className="icon-nav-home max-[423px]:w-[10rem] w-36 animate-waving-hand ">
              <img src={ICON_GIFT_COLOR} className="w-36 h-10" />
            </li>
          ) : (
            <li
              className="navbar__home-Icongift  icon-navbar"
              onClick={handleClickMenuGift}
            ></li>
          )}

          {location?.pathname === "/infor-customer" ? (
            <li className="icon-nav-home max-[423px]:w-[10rem] w-36 animate-waving-hand ">
              <img src={ICON_PROFILE_COLOR} className="w-36 h-10" />
            </li>
          ) : (
            <li
              className="navbar__home-Iconprofile icon-navbar "
              onClick={handleClickMenuProfile}
            ></li>
          )}
          {popup ? (
            <ConfirmPopupLogin
              titlePopup={titlePopup}
              onClosePopup={() => {
                setPopup(false);
              }}
              handleCancel={() => {
                navigation("/");
              }}
              handleOk={() => {
                navigation("/login");
              }}
            />
          ) : null}
        </ul>
        <FooterLogo />
      </div>
    </div>
  );
}
