import "../../assets/css/Login.css";
import React, { useState, useEffect } from "react";
import "../../assets/fontawesome/css/all.min.css";
import "../../assets/css/background.css";
import "../../assets/css/backgroundHeader.css";
import "../../assets/css/backgroundButton.css";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { toast } from "react-toastify";
import "../../assets/css/font-text.css";
import { userServices } from "../../services/apiService/userServices";
import { userDataLocal } from "../../services/localService/localService";
import { setUserData } from "../../Redux/Action/userAction";
import { useDispatch } from "react-redux";
import FooterLogo from "../../component/Footer/FooterHasNavbar";
import { qrServices } from "../../services/apiService/qrservice";
import { setAuthorizationQR } from "../../services/apiService/configQrURL";
import { receiptServices } from "../../services/apiService/receiptServices";
import uuid from "react-uuid";
import { format } from "date-fns";
import SubmitReceipt from "../../component/SubmitReceipt/SubmitReceipt";
import { setAuthorization } from "../../services/apiService/configURL";
import PopupNotify from "../../component/PopupNoti/PopupNoti";

function LoginQRAA() {
  const navigation = useNavigate();
  const token = JSON.parse(localStorage.getItem("TOKEN_QR"));
  let gcsResult = JSON.parse(localStorage.getItem("GCS_RESULT"));
  let appCode = window.location.pathname.split("/")[1];

  console.log(token);
  setAuthorizationQR(token);
  let dispatch = useDispatch();
  const [checkAgree1, setCheckAgree1] = useState(false);
  const [checkAgree2, setCheckAgree2] = useState(false);
  const [notify, setNotify] = useState("");
  const [radio, setRadio] = useState();
  const [loading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [triggerSubmitReceipt, setTriggerSubmitReceipt] = useState(false);
  const handleAgree = (e) => {
    setNotify("");
    if (e === "ag1") {
      setCheckAgree1(!checkAgree1);
    } else if (e === "ag2") {
      setCheckAgree2(!checkAgree2);
    }
  };
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    criteriaMode: "all",
  });
  const [nameCustomer, setNameCustomer] = useState("");
  const [phoneCustomer, setPhoneCustomer] = useState("");
  const [isPopup, setIsOpenPopup] = useState(false);
  useEffect(() => {
    console.log(gcsResult);
    if (!gcsResult) {
      navigation(`/${appCode}/doiqua-inputcode`);
    }
  }, []);
  const onSubmit = (data) => {
    console.log(data.type_phone);
    if (
      data.phone.substr(0, 3) === "087" ||
      data.phone.substr(0, 3) === "055" ||
      data.phone.substr(0, 3) === "099" ||
      data.phone.substr(0, 3) === "059"
    ) {
      setIsOpenPopup(true);
    } else {
      const phoneFormat = {
        phone: data.phone,
        login: data.name,
      };
      setRadio(data.type_phone);
      setNameCustomer(data.name);
      setPhoneCustomer(data.phone);
      console.log(phoneFormat);
      setTriggerSubmitReceipt(true);
      let gcsResult = JSON.parse(localStorage.getItem("GCS_RESULT"));
      // submitReceipt(gcsResult, data.name, data.phone);
    }
  };
  const submitReceipt = (gcsResult, name, phone) => {
    console.log(gcsResult);
    let formData = new FormData();
    formData.append("gsutil_url", gcsResult.gsutil_url);
    formData.append("public_url", gcsResult.public_url);
    formData.append("ocr_result", gcsResult.data);
    //   formData.append(
    //     "gsutil_url",
    //     `gs://mvcpro_vn/2d3c7ea4-8f4b-a2d3-1cfa-cdc5c0803fab_14-05-2024-16-51-11_image.jpg`
    //   );
    //   formData.append(
    //     "public_url",
    //     `https://storage.googleapis.com/mvcpro_vn/2d3c7ea4-8f4b-a2d3-1cfa-cdc5c0803fab_14-05-2024-16-51-11_image.jpg`
    //   );
    //   formData.append(
    //     "ocr_result",
    //     `{
    //     "customer_name": "Coopmart Xa Lo Ha Noi",
    //     "counter": "30",
    //     "cashier": "16113022-Cat Tuyen",
    //     "date_time": "04/10/2023 08:51:11",
    //     "order_number": "03886",
    //     "barcode": "0016103023100403886",
    //     "tax_number": "0305767459",
    //     "runtime": "2.28",
    //     "product_list": [
    //         {
    //             "barcode": "8936065070042",
    //             "description": "ST Purite hoa hong 500ml",
    //             "quantity": 1,
    //             "unitPrice": "159900",
    //             "lineTotalNet": "159900"
    //         },
    //         {
    //             "barcode": "8936065072213",
    //             "description": "ST olive d.am mem min 250ml",
    //             "quantity": 1,
    //             "unitPrice": "75400",
    //             "lineTotalNet": "75400"
    //         }
    //     ],
    //     "promotion": true,
    //     "received_creceipt_datetime": "2024-05-14 16:51:12",
    //     "response_result_datetime": "2024-05-14 16:51:14",
    //     "chain": "coopmart"
    // }`
    //   );
    formData.append("username", userName);
    formData.append("phone_type", radio);
    formData.append("customer_name", name);
    formData.append("phone", phone);
    formData.append("url", `https://marico.urbox.vn/doiqua-inputcode`);
    formData.append(
      "request_id",
      uuid() + "-" + format(new Date(), "ddMMyyyyHHmmss")
    );
    formData.append(
      "receipt_datetime",
      format(new Date(), "yyyy-MM-dd HH:mm:ss")
    );
    if (gcsResult?.campaign_id) {
      formData.append("campaign_id", gcsResult?.campaign_id);
    }
    receiptServices
      .submitReceiptApi(formData)
      .then((res) => {
        console.log("result ", res);
        console.log("soid ", res.so_ids);
      })
      .catch((err) => {
        setIsError(true);
        setMessageError(err);
        console.log("err ", err);
      })
      .finally(() => {
        localStorage.removeItem("GCS_RESULT");
        setLoading(false);
        setTriggerSubmitReceipt(false);
      });
  };
  const handleBack = () => {
    navigation("/");
  };
  const [show, setShowMore] = useState(false);
  const showMore = () => {
    setShowMore(!show);
  };

  const handleNotify = () => {
    setNotify("Vui lòng chọn loại điện thoại đang sử dụng");
  };

  const handleRadio = () => {
    console.log("true1");
    setRadio("basic");
    setNotify("");
  };
  const handleRadio2 = () => {
    console.log("true2");

    setNotify("");
    setRadio("smartphone");
  };
  const handlePopupErrorOk = () => {
    setIsOpenPopup(false);
  };
  return (
    <>
      {triggerSubmitReceipt ? (
        <SubmitReceipt
          phoneCustomer={phoneCustomer}
          nameCustomer={nameCustomer}
          typePhone={radio}
          userName={userName}
          trigger={triggerSubmitReceipt}
        />
      ) : null}
      <div className="container bg_default text-[#fff] w-screen min-w-full h-full min-h-screen px-[25px] flex flex-col box-border">
        <div className="icon-back mt-[7%] py-[36px] h-8 flex items-center opacity-100 max-w-full w-full z-50"></div>
        <header className="p-0 w-52 h-9 leading-9 text-2xl not-italic "></header>
        <div className="container__login-item p-[16px_0_68px_0] text-[#fff] text-base font-light w-[87%]"></div>
        <div className="container__login-form">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__login">
              <div className="flex flex-nowrap h-[70px] rounded-[14px] mt-0  bg-[#fff] relative z-0 border-[#98EBFF] border-[1px]">
                <input
                  className="form__phone  text-[15px] box-border flex-[6]  pl-4 h-full z-10 text-black font-regular-mon rounded-r-xl rounded-l-xl"
                  placeholder="Tên khách hàng"
                  {...register("name", {
                    required: "Không được để trống",
                    pattern: {
                      value: /^[\D*]{1,}$/,
                      message: "Vui lòng chỉ nhập kí tự",
                    },
                  })}
                />
              </div>
              <div className="font-normal z-0 font-[Montserrat-Regular] mb-7 text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ messages }) => {
                    console.log("messages", messages);
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="bg-[#EEE6E7] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#F63440] border-[1px]"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
              </div>

              <div className="flex flex-nowrap h-[70px] rounded-[14px] mt-0  bg-[#fff] relative z-0 border-[#98EBFF] border-[1px]">
                {/* <!-- <input className="form__name" type="text" placeholder="Nhập tên của bạn"/> --> */}

                <input
                  className="form__phone m-[0_0_0_5px] text-[15px] box-border flex-[6] w-52 pl-2.5 h-full z-30 text-black font-regular-mon rounded-r-xl"
                  placeholder="Số điện thoại "
                  type="tel"
                  {...register("phone", {
                    required: "Không được để trống",
                    pattern: {
                      value: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
                      message: "Vui lòng nhập số",
                    },
                  })}
                />
              </div>
              <div className="font-normal z-0 font-[Montserrat-Regular] text-[red] text-[13px] text-center">
                <ErrorMessage
                  errors={errors}
                  name="phone"
                  render={({ messages }) => {
                    console.log("messages", messages);
                    return messages
                      ? Object.entries(messages).map(([type, message]) => (
                          <p
                            key={type}
                            className="bg-[#EEE6E7] pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl border-[#F63440] border-[1px]"
                          >
                            {message}
                          </p>
                        ))
                      : null;
                  }}
                />
                <div className="mt-3"> {notify ? notify : ""}</div>
              </div>

              {show === false ? (
                <>
                  <div
                    className={`${"corlor-text-white"} text-[12px] mt-[5px] font-regular leading-5 whitespace-pre-line flex flex-wrap break-words`}
                  >
                    <div style={{ marginTop: "18px" }}>
                      <div style={{ marginTop: "18px" }}>
                        <div class="flex items-center mb-4">
                          <input
                            id="default-radio-1"
                            type="radio"
                            value="basic"
                            name="default-radio"
                            class="w-4 h-4 text-blue-600"
                            style={{ height: "20px", width: "20px" }}
                            {...register("type_phone", {
                              required: "Vui lòng chọn loại điện thoại",
                            })}
                          />
                          <label
                            for="default-radio-1"
                            class="ms-2 ml-2 text-[16px] font-semibold-mon text-white"
                            // onClick={handleRadio}
                          >
                            Điện thoại thường (Quà Top up điện thoại)
                          </label>
                        </div>
                        <div class="flex items-center">
                          <input
                            id="default-radio-2"
                            type="radio"
                            value="smartphone"
                            name="default-radio"
                            class="w-4 h-4 text-blue-600"
                            style={{ height: "20px", width: "20px" }}
                            {...register("type_phone", {
                              required: "Vui lòng chọn loại điện thoại",
                            })}
                          />
                          <label
                            for="default-radio-2"
                            class="ms-2 ml-2 text-[16px] font-semibold-mon text-white"
                            // onClick={handleRadio2}
                          >
                            Điện thoại thông minh (Quà Evoucher UrBox)
                          </label>
                        </div>
                      </div>
                      <div className="font-normal z-0 font-[Montserrat-Regular] text-[red] text-[13px] text-center">
                        <ErrorMessage
                          errors={errors}
                          name="type_phone"
                          render={({ messages }) => {
                            console.log("messages", messages);
                            return messages
                              ? Object.entries(messages).map(
                                  ([type, message]) => (
                                    <p
                                      key={type}
                                      className=" pt-2.5 -mt-2.5 z-0 rounded-bl-xl rounded-br-xl "
                                    >
                                      {message}
                                    </p>
                                  )
                                )
                              : null;
                          }}
                        />
                        <div className="mt-3"> {notify ? notify : ""}</div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <span
                  className={`${"corlor-text-white"} text-[12px]  font-regular leading-5 whitespace-pre-line flex flex-wrap break-words mt-5`}
                >
                  <span
                    style={{
                      cursor: "pointer",
                      // padding: "5px 0 0 10px",
                      color: `${"#47CCFF"}`,
                      // "#003DA5",
                    }}
                    onClick={() => showMore()}
                  >
                    {show ? "" : "(Xem thêm)"}
                  </span>
                </span>
              )}
              {/* {radio ? ( */}
              <div className="flex justify-center py-[56px] box-border text-[#333] font-light">
                <input
                  type="submit"
                  className="color-button-enable font-bold border-0 text-[#130682] px-[32px] py-[15px] text-center no-underline inline-block rounded-3xl text-[16px] cursor-pointer not-italic font-[Montserrat-Light] leading-5"
                  value={`${"Tiếp tục"}`}
                />
              </div>
              {/* ) : (
                <div className="flex justify-center py-[56px] box-border text-[#333] font-light">
                  <input
                    type="button"
                    className="color-button-disable font-bold border-0 text-[#33333] px-[32px] py-[15px] text-center no-underline inline-block rounded-3xl text-[16px] cursor-pointer not-italic font-[Montserrat-Light]  leading-5"
                    value={`${"Tiếp tục"}`}
                    onClick={handleNotify}
                  />
                </div>
              )} */}
            </div>
          </form>
        </div>
        {/* </div> */}
      </div>
      {/* <FooterLogo /> */}
      {isPopup ? (
        <PopupNotify
          notify={"Thông báo"}
          content={""}
          checkphone={true}
          handleOk={handlePopupErrorOk}
        />
      ) : null}
    </>
  );
}

export default LoginQRAA;
