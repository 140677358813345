import axios from "axios";
import { USER_DATA_LOCAL } from "../localService/localService";
const PHONE_STATUS_NOT_VALIDATE = "start";
const PHONE_STATUS_VALIDATED = "processing";
const data = JSON.stringify({});
let appCode = window.location.pathname.split("/")[1];
export const axiosQrInstance = axios.create({
  baseURL: "https://mrcstaging.mvc.com.vn",
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosOCRInstance = axios.create({
  baseURL: "https://pg.mvc.com.vn",
  headers: {
    "Content-Type": "application/json",
  },
});

export let campaignURL = "https://hfshc.mvc.com.vn/pg";
var url = window.location.href;
console.log(url);

export const configHeader = {
  headers: {
    "Content-Type": "application/json",
  },
};
export const setAuthorizationQR = (token) => {
  axiosQrInstance.defaults.headers.common["TOKEN"] = token;
};

export const checkPhoneStatus = {
  notValidate: PHONE_STATUS_NOT_VALIDATE,
  validated: PHONE_STATUS_VALIDATED,
};

axiosQrInstance.interceptors.response.use(
  function (response) {
    console.log(response);
    if (response.data.result && response.data.result.meta) {
      if (!response.data.result.meta.status) {
        if (response.data.result.meta.status_code === 403) {
          // localStorage.clear();
          window.location.assign(`/${appCode}/doiqua-inputcode`);
        }
        return Promise.reject(response.data.result.meta.message);
      }
    }
    return response.data.result.data ? response.data.result.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
