import { useNavigate } from "react-router-dom";
// import IMG_TITLE from "../../assets/fontawesome/image/notify-yellow.png";
import IMG_TITLE from "../../assets/fontawesome/image/success.png";

import HEADER from "../../assets/fontawesome/image/popup-tc.png";
import { Fragment, useEffect, useInsertionEffect, useState } from "react";
import { homeServices } from "../../services/apiService/homeServices";
import phone_icon from "../../assets/fontawesome/image/phone-icon.png";

export default function PopupNotify({
  notify,
  content,
  handleOk = () => {},
  handleCancel = () => {},
  twoButton,
  checkphone,
}) {
  let appCode = window.location.pathname.split("/")[1];
  const navigation = useNavigate();
  const handleClosePopup = () => {
    localStorage.setItem("linkRedirect", `/guide-takeaphoto`);
    navigation(`/guide-takeaphoto`);
  };
  const [hotline, setHotline] = useState("");
  const getRunningCampaign = () => {
    homeServices
      .getRunningCampaign(appCode)
      .then((res) => {
        console.log(res);
        setHotline(res.campaign_list[0].hotline);
        localStorage.setItem("CAMPAIGN", JSON.stringify(res.campaign_list[0]));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    // getRunningCampaign();
  }, []);
  return (
    <Fragment>
      <div
        className="fixed z-10 overflow-y-auto top-0 w-full left-0 show"
        id="modal"
      >
        <div className="flex items-center justify-center min-height-100vh px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-70" />
            <div className="content-popup-condition w-full">
              <div className="mx-6">
                <div className=" relative -top-20">
                  <img src={HEADER} className="w-full" />
                  <div className="absolute top-11 bg-white w-full min-h-[270px] rounded-3xl px-1">
                    <div className="mt-8 font-bebas-mon text-[36px] text-[#CC0403]">
                      {notify}
                    </div>
                    <div
                      className={`
                      ${checkphone ? "text-justify" : ""}
                       font-regular-mon text-black text-[18px] mt-3 px-[6px]`}
                    >
                      {checkphone ? (
                        <Fragment>
                          Chương trình chỉ hỗ trợ gửi link Evoucher UrBox và nạp
                          điện thoại cho 4 nhà mạng:{" "}
                          <span className="text-[#CC0403] font-bold-mon">
                            Viettel, Vinaphone, Mobifone, Vietnam Mobile
                          </span>
                          . Và không hỗ trợ cho các nhà mạng Itel, Wintel,
                          Gtel... Vui lòng sử dụng số điện thoại của 4 nhà mạng
                          trên để tham gia chương trình.
                        </Fragment>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content,
                          }}
                        ></div>
                      )}
                    </div>
                    <div className="mt-6 flex w-full justify-center mb-5">
                      {twoButton ? (
                        <button
                          onClick={() => handleCancel()}
                          className="bg-[#F3AB1F] mr-5 font-bebas-mon text-[25px] text-[#CC0403] px-10 py-2 rounded-lg"
                        >
                          Để sau
                        </button>
                      ) : null}

                      <button
                        onClick={() => handleOk()}
                        className={`
                        ${checkphone ? "mb-6" : ""}
                         bg-[#014FA2] font-bold-mon text-[25px] text-[#ffffff] px-10 py-2 rounded-lg mb-2"
                      `}
                      >
                        Đồng ý
                      </button>
                    </div>
                  </div>
                  <div className="absolute top-0 translate-y-[25%] left-1/2 -translate-x-1/2">
                    <img src={IMG_TITLE} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
