import "../../assets/css/popupPhone.css";
import Image from "../../assets/fontawesome/image/cancel-phone-icon.png";
import Modal from "react-modal";
import phone_icon from "../../assets/fontawesome/image/phone-icon.png";
import ICON_TAKE_A_PHOTO from "../../assets/fontawesome/image/takeaphoto.png";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { homeServices } from "../../services/apiService/homeServices";

export default function ConfirmPopupBtnHorizontal({
  isShow,
  message,
  labelOk = "Chụp Lại",
  handleOk = () => {},
}) {
  let { token } = useSelector((state) => {
    return state.userReducer.userData;
  });
  const dataCampaign = useQuery({
    queryKey: ["getAllCampaign"],
    queryFn: () => homeServices.getRunningCampaignAll(),
  });
  console.log(dataCampaign);
  // console.log(token);
  return (
    <Modal
      isOpen={isShow}
      // style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
      className="z-50"
    >
      {/* <div className='z-50'> */}
      <div className="popup-confirm-phone absolute top-[25%] w-[92%] mx-4 h-[70px] z-50 ">
        <div className="mt-[40px] rounded-[30px] bg-white border-2 border-solid w-full z-50">
          <img
            src={Image}
            className="image__phone relative top-0 left-1/2 z-50"
          />
          <div className="flex flex-col justify-center items-center z-50">
            <div className="-mt-9 text-[24px] text-[#F63440] z-50">
              THÔNG BÁO
            </div>
            <div className="my-5 w-full font-regular-mon text-[13px] px-10 text-center z-50">
              {message}
            </div>
            <div className="flex justify-around mb-3">
              <a
                href={`tel: ${dataCampaign?.data?.campaign_list[0]?.hotline}`}
                className="bg-[#47CCFF] hotline-btn text-[#130682] flex
                           justify-center items-center rounded-[16px] px-[5px] py-[12px] gap-1 h-12 w-[155px] mr-2"
              >
                <img src={phone_icon} alt="" />
                <span className=" text-[#ffffff] w-full text-[13px]">
                  Liên hệ Hỗ trợ {dataCampaign?.data?.campaign_list[0]?.hotline}
                </span>
              </a>

              <button
                className="font-semibold color-button-blue hotline-btn corlor-text-white flex
                            justify-center items-center rounded-[16px] px-[14px] py-[12px] gap-1 w-36 h-12 ml-2"
                onClick={handleOk}
              >
                {labelOk}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
}
